import { post } from "../util/http"


/**
 * 代理列表分页
 * @param  params 
 */
export const SelectPageProxy = params => post(`/proxy/selectPage`, params)

/**
 * 新增代理
 * @param  params 
 */
export const InsertProxy = params => post(`/proxy/insert`, params)

/**
 * 编辑代理
 * @param  params 
 */
export const UpdateProxy = params => post(`/proxy/update`, params)

/**
 * 删除代理
 * @param  params 
 */
export const DeleteProxy = params => post(`/proxy/delete`, params)