import { post, postJson } from "../../util/http"

/**
 * 查询订单
 * @param  params 
 */
export const getOrders = params => post(`/open-api/apple/get`, params)


/**
 * 上传订单
 * @param  params 
 */
export const putOrders = params => postJson(`/open-api/apple/put`, params)


/**
 * 查询ticket
 * @param  params 
 */
export const getTickets = params => post(`/open-api/ticket/get`, params)


/**
 * 上传ticket
 * @param  params 
 */
export const putTickets = params => postJson(`/open-api/ticket/put`, params)

/**
 * 开始ticket
 * @param  params 
 */
export const startTickets = params => postJson(`/open-api/ticket/start`, params)

/**
 * 登录ticket
 * @param  params 
 */
export const loginTickets = params => post(`/open-api/ticket/login`, params)

/**
 * 取消 任务
 * @param  params 
 */
export const cancelTickets = params => post(`/open-api/ticket/cancel`, params)
/**
 * 取消 任务
 * @param  params 
 */
export const cancelApple = params => post(`/open-api/apple/cancel`, params)


/**
 * 删除 任务
 * @param  params 
 */
export const delTickets = params => post(`/open-api/ticket/del`, params)

/**
 * 定时任务
 * @param  params 
 */
export const startTask = params => post(`/open-api/ticket/task`, params)


/**
 * 清空eid
 * @param  params 
 */
export const clearTicket = params => post(`/open-api/ticket/clear`, params)
