  <!-- 数据库表管理 -->
  <template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line" prop="ip">
              <el-input v-model.trim="search.searchKey" placeholder="根据表名称或说明搜索" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="showDetail" plain>创建表</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="listData" stripe style="width: 100%" border height="calc(100vh - 300px)">
        <el-table-column type="index" label="序号" align="center" width="100px"></el-table-column>
        <el-table-column prop="name" label="表名" align="left" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.name }}
            <el-tag v-if="scope.row.isSystem" size="mini">系统表</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="说明"
          align="left"
          show-overflow-tooltip
          min-width="300"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="250px">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="showDetail(scope.row)">编辑</el-button>
            <el-button size="mini" type="info" @click="showQueryDetail(scope.row)">查询</el-button>
            <el-button
              size="mini"
              type="warning"
              v-if="!scope.row.isSystem"
              @click="deleteRow(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
    </el-main>
    <el-dialog
      :title="form.id ? '编辑表' : '创建表'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="80%"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item
              class="dccform-line"
              label="表名称"
              prop="name"
              required
              :rules="[{required: true, message: '必填'}]"
            >
              <el-input v-model="form.name" :disabled="form.isSystem" placeholder="请输入表名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              class="dccform-line"
              label="表说明"
              prop="remarks"
              required
              :rules="[{required: true, message: '必填'}]"
            >
              <el-input v-model="form.remarks" placeholder="请输入表说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="header">
          <h3 class="label">
            字段设置
            <i class="el-icon-circle-plus canclick" @click="addField"></i>
          </h3>
          <el-dropdown :hide-on-click="false" @command="handleCommand">
            <span class="el-dropdown-link">
              常用字段
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="f.name"
                v-for="(f, i) in defaultField"
                :key="i"
              >{{f.remarks}}:{{ f.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-table ref="table" :data="form.fields" height="calc(100vh - 400px)" size="small">
          <el-table-column prop="name" min-width="150px">
            <template slot="header">
              <span style="color: red;">*</span>
              <span>列名</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                class="table-form"
                :prop="`fields[${scope.$index}].name`"
                required
                :rules="[{required: true, message: '必填'}]"
              >
                <el-input
                  v-model="scope.row.name"
                  :disabled="!!(scope.row.id && form.isSystem)"
                  placeholder="列名"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="type" align="center" min-width="90px">
            <template slot="header">
              <span style="color: red;">*</span>
              <span>类型</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                class="table-form"
                :prop="`fields[${scope.$index}].type`"
                required
                :rules="[{required: true, message: '必填'}]"
              >
                <el-select v-model="scope.row.type" :disabled="!!(scope.row.id && form.isSystem)">
                  <el-option v-for="(f, i) in fieldType" :key="i" :label="f.label" :value="f.name"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="size" label="长度" width="100px" align="center">
            <template slot-scope="scope">
              <el-input
                v-model.number="scope.row.size"
                :disabled="!['DECIMAL', 'VARCHAR'].includes(scope.row.type)"
                placeholder="长度"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="accuracy" label="小数位数" width="100px" align="center">
            <template slot-scope="scope">
              <el-input
                v-model.number="scope.row.accuracy"
                :disabled="scope.row.type != 'DECIMAL'"
                placeholder="小数位数"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="isPrimaryKey" label="是否主键" width="70px" align="center">
            <template slot-scope="scope">
              <el-switch
                size="mini"
                v-model="scope.row.isPrimaryKey"
                :disabled="!!(scope.row.id && form.isSystem)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="isNullable" label="允许空" width="70px" align="center">
            <template slot-scope="scope">
              <el-switch size="mini" v-model="scope.row.isNullable"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="defaultValue" label="默认值">
            <template slot-scope="scope">
              <el-input v-model="scope.row.defaultValue" placeholder="默认值"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="remarks" min-width="120px">
            <template slot="header">
              <span style="color: red;">*</span>
              <span>说明</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                class="table-form"
                :prop="`fields[${scope.$index}].remarks`"
                required
                :rules="[{required: true, message: '必填'}]"
              >
                <el-input v-model="scope.row.remarks" placeholder="说明"></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="80px">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                :disabled="!!scope.row.id"
                @click="form.fields.splice(scope.$index, 1)"
              >删除</el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty description="暂无数据"></el-empty>
          </template>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
        <el-button style="width: 120px;" @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="`${form.remarks}(${form.name})查询`"
      :visible.sync="dialogQueryVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="80%"
    >
      <el-form :model="queryForm" ref="queryForm" @submit.native.prevent>
        <el-row :gutter="20">
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line" prop="name">
              <el-select style="width: 100%;" v-model="queryForm.name" placeholder="请选择查询字段">
                <el-option
                  v-for="(f, i) in form.fields"
                  :key="i"
                  :value="f.name"
                  :label="f.remarks"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line" prop="value">
              <el-input v-model.trim="queryForm.value" placeholder="字段模糊搜索" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="loadQueryData" plain>查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table v-if="dialogQueryVisible" ref="queryTable" :data="queryData" height="calc(100vh - 300px)" size="small">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column
          v-for="(f, i) in form.fields"
          show-overflow-tooltip
          :key="i"
          :prop="f.name"
          :label="f.remarks"
        ></el-table-column>
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="queryForm.current"
        :page-size="queryForm.size"
        :total="queryForm.total"
      ></el-pagination>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  selectList,
  createTable,
  updateTable,
  delTable,
  getTable,
  queryPageTable
} from '@/api/table'
export default {
  data() {
    return {
      dialogVisible: false,
      dialogQueryVisible: false,
      search: {
        searchKey: ''
      },
      imgloading: false,
      form: {
        id: '',
        name: '',
        remarks: '',
        fields: []
      },
      queryForm: {
        name: 'id',
        value: '',
        current: 1,
        size: 20,
        total: 0
      },
      fieldType: [
        {
          name: 'VARCHAR',
          label: '字符串'
        },
        {
          name: 'INT',
          label: '整型'
        },
        {
          name: 'BIGINT',
          label: '长整型'
        },
        {
          name: 'DECIMAL',
          label: '浮点型'
        },
        {
          name: 'DATE',
          label: '日期'
        },
        {
          name: 'TIMESTAMP',
          label: '日期时间'
        },
        {
          name: 'BOOLEAN',
          label: '布尔'
        },
        {
          name: 'TEXT',
          label: '文本'
        },
        {
          name: 'LONGTEXT',
          label: '长文本'
        }
      ],
      defaultField: [
        {
          name: 'id',
          type: 'VARCHAR',
          size: 36,
          accuracy: 0,
          isPrimaryKey: true,
          isNullable: false,
          remarks: '主键'
        },
        {
          name: 'status',
          type: 'INT',
          size: 0,
          accuracy: 0,
          isPrimaryKey: false,
          isNullable: false,
          remarks: '状态'
        },
        {
          name: 'create_time',
          type: 'TIMESTAMP',
          size: 0,
          accuracy: 0,
          isPrimaryKey: false,
          isNullable: false,
          remarks: '创建时间'
        },
        {
          name: 'last_update_time',
          type: 'TIMESTAMP',
          size: 0,
          accuracy: 0,
          isPrimaryKey: false,
          isNullable: false,
          remarks: '最后更新时间'
        }
      ],
      tableData: [],
      queryData: []
    }
  },
  computed: {
    listData() {
      if (this.search.searchKey) {
        return this.tableData.filter(
          (d) =>
            d.name.includes(this.search.searchKey) ||
            d.remarks.includes(this.search.searchKey)
        )
      }
      return this.tableData
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.queryForm.current = val
      this.loadQueryData()
    },
    loadQueryData() {
      let params = Object.assign({}, this.queryForm)
      params.tableName = this.form.name
      queryPageTable(params).then((res) => {
        console.log('queryPageTable', res)
        if (res.code === 20000) {
          this.queryData = res.data.list
          this.queryForm.total = res.data.total
        }
        this.$nextTick(() => {
          this.$refs.queryTable.doLayout()
        })
      })
    },
    async showQueryDetail(row) {
      console.log(row)
      await this.buildDetail(row)
      // 查询
      this.$nextTick(() => {
        this.dialogQueryVisible = true
        this.loadQueryData()
        this.$nextTick(() => {
          this.$refs.queryForm.resetFields()
          this.$refs.queryForm.clearValidate()
        })
      })
    },
    handleCommand(command) {
      console.log(command)
      let obj = this.defaultField.find((d) => d.name == command)
      if (obj) {
        this.form.fields.push(
          Object.assign(
            {
              id: '',
              name: '',
              type: 'VARCHAR',
              size: 128,
              accuracy: 0,
              isPrimaryKey: false,
              isNullable: true,
              defaultValue: '',
              remarks: ''
            },
            obj
          )
        )
        //滚动到最后一行
        this.scrollBottom('table')
      }
    },
    scrollBottom(tabRef) {
      this.$nextTick(() => {
        //此处必须使用nextTick函数,使页面渲染完后再执行
        this.$refs[tabRef].bodyWrapper.scrollTop =
          this.$refs[tabRef].bodyWrapper.scrollHeight
      })
    },
    addField() {
      console.log('add')
      this.form.fields.push({
        id: '',
        name: '',
        type: 'VARCHAR',
        size: 128,
        accuracy: 0,
        isPrimaryKey: false,
        isNullable: true,
        defaultValue: '',
        remarks: ''
      })
      //滚动到最后一行
      this.scrollBottom('table')
    },
    showDetail(row) {
      this.imgloading = true
      this.buildDetail(row)
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    buildDetail(row) {
      this.form = Object.assign(
        {
          id: '',
          name: '',
          remarks: '',
          fields: []
        },
        row
      )
      if (this.form && this.form.id) {
        // 查询表字段
        return getTable({ tableName: row.name }).then((res) => {
          console.log('getTable', res)
          if (res.code === 20000) {
            this.form.fields = res.data.map((d) => {
              d.id = d.name
              d.type = d.type.toUpperCase()
              return d
            })
          }
        })
      }
      return Promise.resolve()
    },
    saveHandle() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          let API = createTable
          if (params.id) {
            API = updateTable
          }
          API(params).then((res) => {
            console.log('保存表', res)
            if (res.code === 20000) {
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.dialogVisible = false
              this.loadData()
            }
          })
        }
      })
    },
    deleteRow(row) {
      console.log(row)
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTable({ tableName: row.id }).then((res) => {
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.loadData()
          }
        })
      })
    },
    loadData() {
      selectList()
        .then((res) => {
          console.log('selectList:', res)
          this.tableData = res.data.map((d) => {
            d.id = d.name
            d.isSystem = d.name.indexOf('base') == 0
            return d
          })
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
  
  <style lang="scss" scoped>
.tableimg {
  width: 45px;
}
::v-deep {
  .el-table__body-wrapper {
    height: calc(100% - 50px) !important;
      }
  .mydialog {
    .el-dialog__body {
      padding: 10px;
      .el-table__body-wrapper {
        height: calc(100% - 40px) !important;
      }
    }
  }
}
.formlayout {
  padding: 20px 40px;
  padding-bottom: 0px;
}
.login-qrcode {
  position: relative;
  .scaned {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    .userImg {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .account {
      margin-top: 20px;
      font-size: 16px;
      color: green;
      font-weight: 700;
    }
  }
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.el-table::before {
  height: 0px;
}
.table-form {
  margin-bottom: 0px;
  ::v-deep {
    .el-form-item__content {
      margin-left: 0px !important;
    }
  }
}
</style>
  