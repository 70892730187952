import { post, postJson } from "../util/http"

/**
 * 列表查询
 * @param  params 
 */
export const selectList = params => post(`/db/selectList`, params)

/**
 * 获取表格
 * @param  params 
 */
export const getTable = params => post(`/db/get`, params)

/**
 * 创建表
 * @param  params 
 */
export const createTable = params => postJson(`/db/table/create`, params)

/**
 * 更新表
 * @param  params 
 */
export const updateTable = params => postJson(`/db/table/update`, params)

/**
 * 删除表
 * @param  params 
 */
export const delTable = params => post(`/db/table/del`, params)


/**
 *  查询表
 * @param  params 
 */
export const queryTable = params => post(`/db/table/query`, params)

/**
 *  查询表
 * @param  params 
 */
export const queryPageTable = params => post(`/db/table/page`, params)