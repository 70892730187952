  <!-- 微信代理管理 -->
  <template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line" prop="ip">
              <el-input
                v-model.trim="search.ip"
                placeholder="IP"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="showDetail" plain>新增代理</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="calc(100vh - 330px)">
        <el-table-column prop="ip" label="代理IP" align="center" min-width="150"></el-table-column>
        <el-table-column prop="port" label="端口" align="center" width="90"></el-table-column>
        <el-table-column prop="type" label="类型" align="center" width="90"></el-table-column>
        <el-table-column prop="account" label="用户名" align="center"></el-table-column>
        <el-table-column prop="password" label="密码" align="center"></el-table-column>
        <el-table-column label="状态" align="center" width="80p">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="changeUserStatus(scope.row)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="添加时间" align="center" min-width="120"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="140px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="primary" @click="showDetail(scope.row)">编辑</el-button>
              <el-button size="mini" type="warning" @click="deleteRow(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      :title="form.id ? '编辑代理' : '新增代理'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="700px"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
        <el-form-item
          class="dccform-line"
          label="代理IP"
          prop="ip"
          required
          :rules="[{required: true, message: '必填'}]"
        >
          <el-input v-model="form.ip" placeholder="请输入代理IP"></el-input>
        </el-form-item>
        <el-form-item
          class="dccform-line"
          label="端口"
          prop="port"
          required
          :rules="[{required: true, message: '必填'}]"
        >
          <el-input v-model="form.port" placeholder="请输入端口"></el-input>
        </el-form-item>
        <el-form-item
          class="dccform-line"
          label="类型"
          prop="type"
          required
          :rules="[{required: true, message: '必填'}]"
        >
          <el-radio-group v-model="form.type">
            <el-radio label="HTTP"></el-radio>
            <el-radio label="SOCKS"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="dccform-line" label="用户名" prop="account">
          <el-input v-model="form.account" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
        <el-button style="width: 120px;" @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  SelectPageProxy,
  InsertProxy,
  UpdateProxy,
  DeleteProxy
} from '@/api/proxy'
export default {
  data() {
    return {
      dialogVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        ip: ''
      },
      imgloading: false,
      form: {
        id: '',
        ip: '',
        port: '',
        type: '',
        account: '',
        password: '',
        status: 0
      },
      tableData: []
    }
  },
  methods: {
    showDetail(row) {
      this.imgloading = true
      this.form = Object.assign(
        {
          id: '',
          ip: '',
          port: '',
          type: '',
          account: '',
          password: '',
          status: 0
        },
        row
      )
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    saveHandle() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          delete params.addTime
          let API = InsertProxy
          if (params.id) {
            API = UpdateProxy
          }
          API(params).then((res) => {
            console.log('保存代理', res)
            if (res.code === 20000) {
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.dialogVisible = false
              this.loadData()
            }
          })
        }
      })
    },
    deleteRow(row) {
      console.log(row)
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteProxy({ id: row.id }).then((res) => {
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.loadData()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    changeUserStatus(row) {
      UpdateProxy(row).then((res) => {
        console.log('UpdateUserStatus', res)
      })
    },
    loadData() {
      SelectPageProxy(this.search)
        .then((res) => {
          console.log('SelectPageProxy:', res)
          this.tableData = res.data.list
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
  
  <style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
.login-qrcode {
  position: relative;
  .scaned {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    .userImg {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .account {
      margin-top: 20px;
      font-size: 16px;
      color: green;
      font-weight: 700;
    }
  }
}
</style>
  