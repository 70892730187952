import { post } from "../../util/http"


/**
 * 列表分页
 * @param  params 
 */
export const SelectPage = params => post(`/biz/apitoken/selectPage`, params)

/**
 * 列表分页
 * @param  params 
 */
export const SelectUsedPage = params => post(`/biz/apitoken/used/selectPage`, params)

/**
 * 新增
 * @param  params 
 */
export const InsertHandle = params => post(`/biz/apitoken/insert`, params)

/**
 * 编辑
 * @param  params 
 */
export const UpdateHandle = params => post(`/biz/apitoken/update`, params)

/**
 * 删除
 * @param  params 
 */
export const DeleteHandle = params => post(`/biz/apitoken/delete`, params)


/**
 * 获取统计
 * @param  params 
 */
export const getUsedStat = params => post(`/biz/apitoken/used/stat`, params)


/**
 * 充值
 * @param  params 
 */
export const rechargeMoney = params => post(`/biz/apitoken/recharge`, params)

