<template>
  <div class="page">
    <iframe class="magic" src="/api/magic/web/index.html" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  mounted() {
    window.MAGIC_EDITOR_CONFIG = {
      request: {
        beforeSend: function(config){
          let token = sessionStorage.getItem("token")
            // 如果是基于Cookie验证的，此处可以不配。
          config.headers.token = token
          return config;
        }
      }, 
      getMagicTokenValue: function(){
          let token = sessionStorage.getItem("token")
          return token;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  .magic {
  height: 100vh;
  width: 100%;
}
}

</style>