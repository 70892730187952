<template>
  <div class="page">
    <iframe class="docs" src="/api/swagger-ui.html" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  .docs {
    margin-top: -100px;
  height: calc(100vh - 30px);
  width: 100%;
}
}

</style>