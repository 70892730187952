  <!-- 系统设置 -->
  <template>
  <div class="m-t-20">
    <div class="main-body-search">
      <div>
        <el-form
          ref="form"
          class="formlayout"
          :model="form"
          label-width="140px"
          label-position="right"
        >
          <el-form-item
            class="dccform-line"
            label="系统名称"
            prop="sysName"
            required
            :rules="[{required: true, message: '必填'}]"
          >
            <el-input v-model="form.sysName" clearable placeholder="请输入" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item
            class="dccform-line"
            label="系统描述"
            prop="sysNameZw"
          >
            <el-input v-model="form.sysNameZw" clearable placeholder="请输入" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item
            class="dccform-line"
            label="版权信息"
            prop="footerMsg"
          >
            <el-input v-model="form.footerMsg" clearable placeholder="请输入" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item
            class="dccform-line"
            label="备案号"
            prop="icp"
          >
            <el-input v-model="form.icp" clearable placeholder="请输入" maxlength="50"></el-input>
          </el-form-item>
        
          <el-form-item>
            <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { UploadFile, selectFiles, DeleteFile, SelectConfig, SaveConfig } from '@/api'
export default {
  data() {
    return {
      loading: false,
      form: {
        sysName: '',
        sysNameZw: '',
        footerMsg: '',
        icp: '',
        appName: '',
        address: '',
        route: '',
        latlng: ''
      },
      fileList: []
    }
  },
  methods: {
    async uploadFiles() {
      return await Promise.all(
        this.fileList
          .filter((d) => d.status != 'finish')
          .map((file) => {
            this.loading = true
            let formData = new FormData()
            formData.append('file', file.raw)
            formData.append('fileName', file.name)
            formData.append('type', 'banner')
            formData.append('refId', 'app-banner')
            return UploadFile(formData, (e) => {
              console.log('进度', e)
            }).then((res) => {
              console.log('UploadFile', res)
              this.loading = false
              let ff = this.fileList.find((d) => d.uid === file.uid)
              if (ff) {
                ff.status = 'finish'
              }
            })
          })
      )
    },
    async saveHandle() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // 保存图片
          if (this.fileList.some((d) => d.status !== 'finish')) {
            await this.uploadFiles()
          }
          // 保存
          let params = Object.assign({}, this.form)
          SaveConfig(params).then((res) => {
            console.log('SaveConfig', res)
            if (res.code === 20000) {
              this.$store.commit('setAppConfig', this.form)
              this.$message({
                type: 'success',
                message: '保存成功'
              })
            }
          })
        }
      })
    },
    delFile(index) {
      if (this.fileList[index].status === 'finish') {
        DeleteFile({id: this.fileList[index].id}).then(res => {
          console.log('DeleteFile', res)
          if (res.code === 20000) {
            this.fileList.splice(index, 1)
          }
        })
      } else {
        this.fileList.splice(index, 1)
      }
    },
    onChange(file) {
      console.log(file)
      if (!this.beforeAvatarUpload(file)) {
        return
      }
      file.url = URL.createObjectURL(file.raw)
      this.fileList.push(file)
    },
    beforeAvatarUpload(file) {
      file.type = file.raw.type
      const isJPG =
        ['image/jpg', 'image/png', 'image/jpeg'].indexOf(file.type) !== -1
      const isLt5M = file.raw.size / 1024 / 1024 < 50
      if (!isJPG) {
        this.$message.error('上传文件只能是 jpg/png格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 50MB!')
      }
      return isJPG && isLt5M
    },
    loadData() {
      // 加载数据
      SelectConfig().then((res) => {
        console.log('SelectConfig', res)
        if (res.code === 20000) {
          this.form = Object.assign(
            {
              sysName: '',
              sysNameZw: '',
              footerMsg: '',
              icp: '',
              appName: '',
              address: '',
              route: '',
              latlng: ''
            },
            res.data
          )
        }
      })
      // 加载图片
      selectFiles({
        refId: 'app-banner',
        type: 'banner'
      }).then(res => {
        console.log('selectFiles', res)
        if (res.code === 20000) {
          this.fileList = res.data.map(d => {
            return {
              status: 'finish',
              id: d.id,
              uid: d.id,
              url: `/api/file/get?id=${d.id}&token=${sessionStorage.getItem('token')}`
            }
          })
        }
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
  
  <style lang="scss" scoped>
.formlayout {
  padding: 20px 40px;
}
.banner {
  position: relative;
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  overflow: hidden;
  .banner-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .el-icon-error {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
  