<template>
  <div :id="id"></div>
</template>

<script>
import echarts from 'echarts'
export default {
  props: {
    id: String,
    data: Object
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    data: {
      handler() {
        this.init()
      },
      deep: true
    }
  },
  methods: {
    init() {
      console.log('init============')

      let dom = document.getElementById(this.id)
      if (!dom) {
        return
      }
      this.chart = echarts.init(dom)
      if (this.data) {
        this.chart.setOption(this.data)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
    window.addEventListener('resize', () => {
      if (this.chart) {
        this.chart.resize()
      }
    })
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.clear()
    }
  }
}
</script>

<style></style>
