  <!-- 角色管理 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.username"
                placeholder="角色名称"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="showDetail({})" plain>新建角色</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="calc(100vh - 330px)">
        <!-- <el-table-column prop="id" label="角色ID" align="center" width="180"></el-table-column> -->
        <el-table-column prop="role" label="角色名称" align="center" width="180"></el-table-column>
        <el-table-column prop="roleDesc" show-overflow-tooltip label="描述" align="center"></el-table-column>

        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="lastUpdateTime" label="更新时间" align="center"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="160px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="primary" @click="showDetail(scope.row)">编辑</el-button>
              <el-button size="mini" type="warning" v-if="scope.row.id!='admin'" @click="deleteRow(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      :title="form.id ? '编辑角色' : '新增角色'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="700px"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
        <el-form-item
          class="dccform-line"
          label="角色名称"
          prop="role"
          required
          :rules="[{required: true, message: '必填'}]"
        >
          <el-input v-model="form.role" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item
          class="dccform-line"
          label="角色描述"
          prop="roleDesc"
          required
          :rules="[{required: true, message: '必填'}]"
        >
          <el-input v-model="form.roleDesc" type="textarea" maxlength="200" placeholder="请输入角色描述"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="菜单">
          <el-tree ref="tree" :data="menu" show-checkbox node-key="id" :default-expand-all="true" :props="defaultProps"></el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
        <el-button style="width: 120px;" @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { SelectPageRole, SaveRole, DeleteRole, GetRoleMenu } from '@/api'
import MenuData from '@/router/menu.config.js'
const toTree = (data) => {
  return data.map(d => {
    let children = data.filter(j => j.parentId &&  j.parentId === d.id)
    if (children) {
      d.children = children
    }
    return d
  }).filter(d => !d.parentId)
}
export default {
  data() {
    return {
      dialogVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        role: ''
      },
      form: {
        id: '',
        role: '',
        roleDesc: ''
      },
      menu: toTree(MenuData),
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },
      tableData: []
    }
  },
  methods: {
    showDetail(row) {
      console.log(toTree(MenuData))
      this.form = Object.assign(
        {
          id: '',
          role: '',
          roleDesc: ''
        },
        row
      )
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([])
        this.$refs.form.clearValidate()
        if (row.id) {
          this.getRoleMenu(row.id)
        }
      })
    },
    getRoleMenu (roleId) {
      GetRoleMenu({roleId}).then(res => {
        console.log('GetRoleMenu', res)
        if (res.code === 20000) {
          let menuids = res.data.map(d => {
            let m = MenuData.find(j => j.menuUri == d.menu)
            if (m) {
              return m.id
            }
            return ''
          }).filter(d => d)
          console.log(menuids)
          this.$refs.tree.setCheckedKeys(menuids)
        }
      })
    },
    deleteRow(row) {
      console.log(row)
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteRole({ id: row.id }).then((res) => {
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.loadData()
          }
        })
      })
    },
    saveHandle() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          delete params.lastUpdateTime
          delete params.createTime
          let nodes = this.$refs.tree.getCheckedNodes()
          console.log(nodes);
          params.menus = nodes.map(d => d.menuUri).join(',')
          SaveRole(params).then((res) => {
            console.log('SaveRole', res)
            if (res.code === 20000) {
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.dialogVisible = false
              this.loadData()
            }
          })
        }
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    loadData() {
      SelectPageRole(this.search)
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data.list
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
</style>
