  <!-- ticket -->
  <template>
  <div class="m-t-20">
    <el-main class="main-body-table">
      <vxe-toolbar ref="toolbarRef" :refresh="{queryMethod: searchHandle}">
        <template #tools>
          <el-button
            type="primary"
            :disabled="!(goodsUrl && tableData && tableData.length > 0)"
            size="small"
            @click="showAutoTask"
          >定时抢票<span v-if="taskisrun">({{ taskisrun }})</span></el-button>
          <!-- <el-button type="primary" :disabled="!(goodsUrl && tableData && tableData.length > 0)" size="small" @click="loginData" :loading="orderloading">批量登录</el-button> -->
          <el-button
            style="margin-right: 10px;"
            :disabled="!(goodsUrl && tableData && tableData.length > 0)"
            :type="!isrun? 'success' :'warning'"
            size="small"
            @click="runTask"
          >{{!isrun ? '开始抢票' : '暂停抢票'}}</el-button>
          <vxe-button
            style="margin-right: 10px;"
            icon="vxe-icon-download"
            circle
            @click="exportExcel"
          ></vxe-button>
        </template>
        <template #buttons>
          <input
            id="excel-upload-input"
            type="file"
            accept="application/*"
            class="c-hide"
            @change="handkeFileChange"
            v-show="false"
          />
          <el-button
            :type="goodsUrl? 'primary' :'warning'"
            size="small"
            @click="setProxyIp"
            :loading="orderloading"
          >{{goodsUrl ? '修改配置' : '抢购商品未设置'}}</el-button>
          <el-button type="primary" size="small" @click="importData" :loading="orderloading">上传账户</el-button>
          <div style="margin-left: 20px;text-align:left;max-width: 800px;">
            上传excel，第一列是账户，第二列密码
            <div style="color: red;" v-if="goodsUrl">抢购商品:{{ goodsUrl }}</div>
          </div>
        </template>
      </vxe-toolbar>
      <div style="height: calc(100vh - 290px);">
        <vxe-table
          border
          show-overflow
          :data="tableData"
          v-loading="loading"
          resizable
          align="center"
          ref="tableRef"
          height="auto"
          :row-config="{isHover: true}"
          :export-config="{ filename: '抢票', sheetName: '数据', types: ['xlsx']}"
          :sort-config="{trigger: 'cell'}"
          :scroll-y="{enabled: true}"
        >
          <vxe-column type="seq" fixed="left" width="80"></vxe-column>
          <vxe-column field="username" title="账户" fixed="left" min-width="150px"></vxe-column>
          <vxe-column field="password" title="密码" min-width="100px"></vxe-column>
          <!-- <vxe-column field="goodsUrl" title="商品地址" min-width="130px"></vxe-column> -->
          <vxe-column field="threadId" title="线程名称" width="150px"></vxe-column>
          <vxe-column field="proxyIp" title="使用代理" min-width="150px">
            <template #default="{ row }">
              <template v-if="row.proxyIp">{{ row.proxyIp }}:{{ row.proxyPort }}</template>
              <span v-else>-</span>
            </template>
          </vxe-column>
          <vxe-column field="logined" title="是否已登录" width="100px">
            <template #default="{ row }">
              <el-tag v-if="row.logined" type="success">已登录</el-tag>
              <el-tag v-else type="danger">未登录</el-tag>
            </template>
          </vxe-column>
          <vxe-column field="query" title="查询次数" width="100px"></vxe-column>
          <vxe-column field="status" title="状态" width="80px">
            <template #default="{ row }">
              <el-tag v-if="row.status == '成功'" type="success">成功</el-tag>
              <el-tag v-else-if="row.status && row.status.indexOf('失败') != -1" type="danger">失败</el-tag>
              <el-tag v-else-if="row.status && row.status.indexOf('异常') != -1" type="danger">异常</el-tag>
            </template>
          </vxe-column>
          <vxe-column field="statusName" title="状态内容" width="130px" show-overflow-tooltip></vxe-column>

          <vxe-column title="操作" width="100" fixed="right">
            <template #default="{ row , rowIndex}">
              <template v-if="!row.loading">
                <!-- <vxe-button type="text" v-if="row.first" status="primary" @click="refresh(row)" icon="vxe-icon-refresh"></vxe-button> -->
                <vxe-button
                  type="text"
                  status="primary"
                  @click="deleteRow(row, rowIndex)"
                  icon="vxe-icon-delete"
                ></vxe-button>
              </template>
              <span v-else>查询中</span>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <div class="footer">
        <div>总 {{ tableData.length }} 条数据</div>
      </div>
    </el-main>
    <el-dialog
      title="设置"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="700px"
    >
      <el-form
        ref="form"
        class="formlayout"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              class="dccform-line"
              label="商品地址"
              prop="goodsUrl"
              required
              :rules="[{required: true, message: '必填'}]"
            >
              <el-input v-model="form.goodsUrl" placeholder="请输入商品地址">
                <template slot="suffix">
                  <el-button type="warning" size="mini" @click="clearTicket">清空EID缓存</el-button>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="dccform-line" label="购买数量" prop="ticketNum">
              <el-input v-model.number="form.ticketNum" type="number" placeholder="请输入购买数量"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="dccform-line" label="票类型" prop="ticketId">
              <el-input v-model.number="form.ticketId" type="number" placeholder="请输入票类型"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
              <el-form-item class="dccform-line" label="监控库存代理IP" label-width="140px" prop="inventoryProxyIp">
                <el-input v-model="form.inventoryProxyIp" placeholder="请输入代理地址（ip:端口）"></el-input>
              </el-form-item>
            </el-col>
          <el-col :span="12">
            <el-form-item class="dccform-line" label="代理类型" prop="proxyType">
             <el-select v-model="form.proxyType">
              <el-option label="动态代理" value="http"></el-option>
              <el-option label="服务器多IP出口" value="socks"></el-option>
            </el-select>
            </el-form-item>
          </el-col>
       
          <template v-if="form.proxyType == 'http'">
            <el-col :span="24">
              <el-form-item class="dccform-line" label="代理服务" prop="proxyIp">
                <el-input v-model="form.proxyIp" placeholder="请输入代理服务地址（ip:端口）"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item class="dccform-line" label="代理过期时间" prop="proxyTimeout">
                <el-input v-model.number="form.proxyTimeout" min="0" type="number" placeholder="请输入">
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="dccform-line" label="异常执行次数" prop="failsize">
                <el-input v-model.number="form.failsize" min="0" type="number" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col v-else :span="24">
              <el-form-item class="dccform-line" label="服务器IP" prop="serverIp">
                <el-input v-model="form.serverIp" type="textarea" :rows="10" placeholder="请输入代理服务地址（ip:端口）"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                class="dccform-line"
                label-width="170px"
                label="异常是否重新登录"
                prop="reLogin"
              >
                <el-switch v-model="form.reLogin" active-color="#13ce66" inactive-color="#999"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item class="dccform-line" label="请求1并发个数" label-width="140px" prop="taskReq1Num">
              <el-input v-model.number="form.taskReq1Num" min="0" type="number" placeholder="请输入">
              </el-input>
            </el-form-item>
          </el-col>
            <!-- <el-col :span="12">
              <el-form-item
                class="dccform-line"
                label-width="120px"
                label="302后请求间隔"
                prop="sleeptime"
              >
              <el-input v-model.number="form.sleeptime" min="0" type="number" placeholder="请输入">
                <template slot="append">毫秒</template>
              </el-input>
              </el-form-item>
            </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
        <el-button style="width: 120px;" @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="定时抢票设置"
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="700px"
    >
      <el-form
        ref="form"
        class="formlayout"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item class="dccform-line" label="定时登录时间" prop="taskLoginTime">
              <el-date-picker
                v-model="form.taskLoginTime"
                type="datetime"
                style="width: 100%"
                value-format="timestamp"
                placeholder="选择日期时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item class="dccform-line" label="定时抢票时间" prop="taskStartTime">
              <el-date-picker
                v-model="form.taskStartTime"
                type="datetime"
                style="width: 100%"
                value-format="timestamp"
                placeholder="选择日期时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label-width="0px"  label="" prop="mmtime">
              <el-input v-model.number="form.mmtime" type="number" :min="0" :max="999" maxlength="3" placeholder="请输入">
                <template slot="append">毫秒</template>
              </el-input>
            </el-form-item>
          </el-col>
        <el-col :span="14">
            <el-form-item  label="请求1时间" prop="taskReq1Time">
              <el-date-picker
                v-model="form.taskReq1Time"
                type="datetime"
                style="width: 100%"
                value-format="timestamp"
                placeholder="选择日期时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label-width="0px"  label="" prop="mmtime1">
              <el-input v-model.number="form.mmtime1" type="number" :min="0" :max="999" maxlength="3" placeholder="请输入">
                <template slot="append">毫秒</template>
              </el-input>
            </el-form-item>
          </el-col>
           <!--  <el-col :span="14">
            <el-form-item class="dccform-line" label="请求2时间" prop="taskReq2Time">
              <el-date-picker
                v-model="form.taskReq2Time"
                type="datetime"
                style="width: 100%"
                value-format="timestamp"
                placeholder="选择日期时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label-width="0px"  label="" prop="mmtime2">
              <el-input v-model.number="form.mmtime2" type="number" :min="0" :max="999" maxlength="3" placeholder="请输入">
                <template slot="append">毫秒</template>
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
              <el-form-item class="dccform-line" label="抢票次数" prop="failsize">
                <el-input v-model.number="form.failsize" min="0" type="number" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
         
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveHandle2">保存</el-button>
        <el-button style="width: 120px;" @click="dialogVisible2 = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
      
      <script>
import {
  putTickets,
  getTickets,
  startTickets,
  loginTickets,
  cancelTickets,
  delTickets,
  startTask,
  clearTicket
} from '@/api/biz/openapi'
import Excel from 'exceljs'
export default {
  data() {
    return {
      search: {},
      options: [],
      proxyIp: '',
      inventoryProxyIp: '',
      goodsUrl: '',
      ticketNum: 1,
      proxyTimeout: 0,
      sleeptime: 0,
      proxyType: 'http',
      serverIp: '',
      reLogin: true,
      taskStartTime: '',
      taskReq1Time: '',
      taskReq2Time: '',
      taskLoginTime: '',
      taskReq1Num: 5,
      ticketId: 1,
      isrun: false,
      loading: false,
      orderloading: false,
      dialogVisible: false,
      dialogVisible2: false,
      form: {
        goodsUrl: '',
        ticketNum: '',
        proxyTimeout: 0,
        sleeptime: 0,
        proxyType: 'http',
        serverIp: '',
        reLogin: true,
        taskStartTime: '',
        taskReq1Time: '',
        taskReq2Time: '',
        taskLoginTime: '',
        taskReq1Num: 5,
        failsize: 3,
        ticketId: '',
        proxyIp: '',
        inventoryProxyIp: '',
      },
      interval: null,
      time: new Date().getTime(),
      eventSource: null,
      clientId: '',
      total: 0,
      idexData: [],
      tableData: []
    }
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close()
    }
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  computed: {
    taskisrun() {
      let now = this.time
      if (!this.taskStartTime) {
        return ''
      }
      if (now < this.taskStartTime) {
        return '未开始'
      }
      if (!this.isrun) {
        return '已结束'
      }
      if (now > this.taskStartTime) {
        return '运行中'
      }
      return ''
    }
  },
  methods: {
    exportExcel() {
      this.$refs.tableRef.exportData({
        type: 'xlsx',
        filename: '抢票',
        sheetName: '数据',
        columnFilterMethod({ column }) {
          return ['username', 'password', 'status'].includes(column.field)
        }
      })
    },
    setProxyIp() {
      this.form = {
        goodsUrl: this.goodsUrl,
        ticketNum: this.ticketNum,
        proxyTimeout: this.proxyTimeout,
        taskReq1Num: this.taskReq1Num,
        sleeptime: this.sleeptime,
        proxyType: this.proxyType,
        serverIp: this.serverIp,
        reLogin: this.reLogin,
        failsize: this.failsize,
        ticketId: this.ticketId,
        inventoryProxyIp: this.inventoryProxyIp,
        proxyIp: this.proxyIp
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    showAutoTask() {
      let mmtime = 0
      let taskStartTime = ''
      if (this.taskStartTime) {
        taskStartTime = parseInt(this.taskStartTime / 1000) * 1000
        mmtime = this.taskStartTime - taskStartTime
      }
      let mmtime1 = 0
      let taskReq1Time = ''
      if (this.taskReq1Time) {
        taskReq1Time = parseInt(this.taskReq1Time / 1000) * 1000
        mmtime1 = this.taskReq1Time - taskReq1Time
      }
      let mmtime2 = 0
      let taskReq2Time = ''
      if (this.taskReq2Time) {
        taskReq2Time = parseInt(this.taskReq2Time / 1000) * 1000
        mmtime2 = this.taskReq2Time - taskReq2Time
      }

      this.form = {
        goodsUrl: this.goodsUrl,
        taskStartTime: taskStartTime,
        taskReq1Time: taskReq1Time,
        taskReq2Time: taskReq2Time,
        taskLoginTime: this.taskLoginTime,
        failsize: this.failsize,
        mmtime,
        mmtime1,
        mmtime2
      }
      this.dialogVisible2 = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    saveHandle2() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          console.log(params)
          this.taskLoginTime = this.form.taskLoginTime
          this.taskStartTime = this.form.taskStartTime
          if (this.form.taskStartTime) {
            this.taskStartTime = this.form.taskStartTime + this.form.mmtime
          }
          this.taskReq1Time = this.form.taskReq1Time
          if (this.form.taskReq1Time) {
            this.taskReq1Time = this.form.taskReq1Time + this.form.mmtime1
          }
          this.taskReq2Time = this.form.taskReq2Time
          if (this.form.taskReq2Time) {
            this.taskReq2Time = this.form.taskReq2Time + this.form.mmtime2
          }
          localStorage.setItem('config-ticket-failsize', this.form.failsize)
          this.failsize = this.form.failsize
          startTask({
            proxyIp: this.proxyType == 'http' ? this.proxyIp : this.serverIp,
            taskStartTime: this.taskStartTime,
            taskReq1Time: this.taskReq1Time,
            taskReq2Time: this.taskReq2Time,
            inventoryProxyIp: this.inventoryProxyIp,
            taskLoginTime: this.taskLoginTime,
            taskReq1Num: this.taskReq1Num || 5,
            goodsUrl: this.goodsUrl,
            proxyTimeout: this.proxyTimeout,
            sleeptime: this.sleeptime,
            proxyType: this.proxyType,
            ticketId: this.ticketId,
            ticketNum: this.ticketNum,
            failsize: this.failsize,
            reLogin: this.reLogin
          }).then((res) => {
            console.log('startTask', res)
            if (res.code === 20000) {
              this.dialogVisible2 = false
              this.$message({
                type: 'success',
                message: '操作成功'
              })
            }
          })
        }
      })
    },
    saveHandle() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          console.log(params)
          localStorage.setItem('config-ticket-goodsUrl', this.form.goodsUrl)
          this.goodsUrl = this.form.goodsUrl
          localStorage.setItem('config-ticket-proxyIp', this.form.proxyIp)
          this.proxyIp = this.form.proxyIp
          localStorage.setItem('config-ticket-ticketId', this.form.ticketId)
          this.ticketId = this.form.ticketId
          localStorage.setItem('config-ticket-ticketNum', this.form.ticketNum)
          this.ticketNum = this.form.ticketNum
          localStorage.setItem('config-ticket-failsize', this.form.failsize)
          this.failsize = this.form.failsize
          localStorage.setItem('config-ticket-reLogin', this.form.reLogin)
          this.reLogin = this.form.reLogin
          localStorage.setItem('config-ticket-proxyType', this.form.proxyType)
          this.proxyType = this.form.proxyType
          localStorage.setItem('config-ticket-serverIp', this.form.serverIp)
          this.serverIp = this.form.serverIp
          localStorage.setItem('config-ticket-sleeptime', this.form.sleeptime)
          this.sleeptime = this.form.sleeptime
          localStorage.setItem('config-ticket-taskReq1Num', this.form.taskReq1Num)
          this.taskReq1Num = this.form.taskReq1Num
          localStorage.setItem('config-ticket-inventoryProxyIp', this.form.inventoryProxyIp)
          this.inventoryProxyIp = this.form.inventoryProxyIp
          localStorage.setItem(
            'config-ticket-proxyTimeout',
            this.form.proxyTimeout
          )
          this.proxyTimeout = this.form.proxyTimeout
          this.dialogVisible = false
          this.$message({
            type: 'success',
            message: '操作成功'
          })
        }
      })
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0
          const v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    },
    startSse() {
      if (this.eventSource) {
        this.eventSource.close()
      }
      this.eventSource = new EventSource(
        `${this.$store.getters.baseApi}/open-api/ticket/events?id=${this.clientId}`
      )
      this.eventSource.onmessage = (event) => {
        console.log('event', event)
        if (event.lastEventId != this.clientId) {
          return
        }
        // 结束
        if (event.data == 'finish') {
          this.orderloading = false
          this.isrun = false
        } else if (event.data == 'doing') {
          console.log('运行中')
        } else if (event.data == 'start') {
          this.$message({ message: '开始定时任务抢票', type: 'primary' })
        } else {
          let obj = JSON.parse(event.data)
          console.log(obj)
          obj.statusName = obj.status
          let index = this.tableData.findIndex(
            (d) => d.username == obj.username
          )
          // 删除原来的
          let list = this.tableData.filter((d) => d.username != obj.username)
          list.splice(index, 0, obj)
          console.log('list', list)
          this.tableData = list
        }
      }
    },
    searchHandle() {
      this.loadData()
    },
    loadPage() {
      getTickets({}).then((res) => {
        console.log('getTickets:', res)
        this.tableData = res.data.data.map((d) => {
          d.statusName = d.status
          return d
        })
        this.orderloading = res.data.todo
        this.taskStartTime = res.data.task
        this.taskReq1Time = res.data.req1
        this.taskReq2Time = res.data.req2
        this.taskLoginTime = res.data.login
        this.isrun = this.orderloading
        this.$nextTick(() => {
          this.loading = false
        })
      })
    },
    refresh(row) {
      // 刷新账户
      row.loading = true
      let index = this.tableData.findIndex((d) => d.username == row.username)
      this.tableData.splice(index, row.size, row)
      this.uploadData([row], true)
    },
    uploadData(data, append = false) {
      this.orderloading = true
      putTickets({
        proxyIp: this.proxyIp,
        users: data,
        append
      }).then((res) => {
        console.log('putTickets', res)
        this.orderloading = false
      })
    },
    clearTicket() {
      this.$confirm('确定清空EID缓存信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        clearTicket({
          goodsUrl: this.goodsUrl
        }).then((res) => {
          console.log('clearTicket', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
          }
        })
      })
    },
    runTask() {
      if (this.isrun) {
        this.isrun = false
        this.cancelData()
      } else {
        this.isrun = true
        this.startData()
      }
    },
    cancelData() {
      this.orderloading = true
      cancelTickets({}).then((res) => {
        console.log('cancelTickets', res)
        this.orderloading = false
        this.loadData()
      })
    },
    deleteRow(row, index) {
      console.log(row)
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTickets({ username: row.username }).then((res) => {
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.tableData.splice(index, 1)
          }
        })
      })
    },
    startData(data, append = false) {
      this.orderloading = true
      this.tableData = this.tableData.map((d) => {
        d.status = ''
        d.query = ''
        d.statusName = ''
        return d
      })
      startTickets({
        proxyIp: this.proxyType == 'http' ? this.proxyIp : this.serverIp,
        goodsUrl: this.goodsUrl,
        proxyTimeout: this.proxyTimeout,
        sleeptime: this.sleeptime,
        proxyType: this.proxyType,
        inventoryProxyIp: this.inventoryProxyIp,
        failsize: this.failsize,
        ticketId: this.ticketId,
        ticketNum: this.ticketNum,
        taskReq1Num: this.taskReq1Num || 5,
        reLogin: this.reLogin,
        users: data,
        append
      }).then((res) => {
        console.log('startTickets', res)
        this.startSse()
      })
    },
    loginData() {
      this.orderloading = true
      loginTickets({
        proxyIp: this.proxyIp,
        goodsUrl: this.goodsUrl,
        proxyTimeout: this.proxyTimeout
      }).then((res) => {
        console.log('loginTickets', res)
        this.startSse()
      })
    },
    loadData() {
      this.loading = true
      this.tableData = []
      this.loadPage(1)
    },
    importData() {
      document.getElementById('excel-upload-input').click()
    },
    getCellValue(value) {
      if (typeof value == 'object') {
        return value.text
      }
      return value
    },
    handkeFileChange() {
      // 导入
      let that = this
      let file = document.getElementById('excel-upload-input').files[0]
      console.log('file', file)
      const buffer = new FileReader()
      buffer.readAsArrayBuffer(file)
      buffer.onload = () => {
        var workbook = new Excel.Workbook()
        document.getElementById('excel-upload-input').value = null
        workbook.xlsx.load(buffer.result).then((workbook) => {
          console.log(workbook)
          // 获取工作表
          const worksheet = workbook.getWorksheet(1)
          // 迭代工作表中具有值的所有行
          let data = []
          worksheet.eachRow((row, rowNumber) => {
            console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values))
            if (rowNumber > 1 && row.values[1] && row.values[2]) {
              data.push({
                username: that.getCellValue(row.values[1]),
                password: that.getCellValue(row.values[2])
              })
            }
          })
          console.log(data)
          that.idexData = data.map((d) => d.username)
          that.tableData = data.map((d, i) => {
            d.first = true
            d.index = i + 1
            d.status = '-'
            return d
          })
          that.uploadData(data)
        })
      }
    }
  },
  async mounted() {
    this.$nextTick(() => {
      // 将表格和工具栏进行关联
      const $table = this.$refs.tableRef
      const $toolbar = this.$refs.toolbarRef
      if ($table && $toolbar) {
        $table.connect($toolbar)
      }
    })
    this.goodsUrl = localStorage.getItem('config-ticket-goodsUrl') || ''
    this.proxyIp = localStorage.getItem('config-ticket-proxyIp') || ''
    this.inventoryProxyIp = localStorage.getItem('config-ticket-inventoryProxyIp') || ''
    this.ticketId = localStorage.getItem('config-ticket-ticketId') || 1
    this.ticketNum = localStorage.getItem('config-ticket-ticketNum') || 1
    this.proxyTimeout = localStorage.getItem('config-ticket-proxyTimeout') || 0
    this.sleeptime = localStorage.getItem('config-ticket-sleeptime') || 0
    this.proxyType = localStorage.getItem('config-ticket-proxyType') || 'http'
    this.serverIp = localStorage.getItem('config-ticket-serverIp') || ''
    this.reLogin = localStorage.getItem('config-ticket-reLogin') || true
    this.failsize = localStorage.getItem('config-ticket-failsize') || 3
    this.taskReq1Num = localStorage.getItem('config-ticket-taskReq1Num') || 5
    this.clientId = this.generateUUID()
    this.startSse()
    this.loadData()
    this.interval = setInterval(() => {
      this.time = new Date().getTime()
    }, 1000)
  }
}
</script>
      
  <style lang="scss" scoped>
.formlayout {
  padding: 20px 40px;
}
.changed {
  color: $primary-color;
}
::v-deep {
  .el-table__body-wrapper {
    height: calc(100% - 40px) !important;
  }
  .el-input__inner {
    height: 34px;
  }
  .el-form-item__content {
    line-height: 34px;
  }
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.labelsele {
  ::v-deep {
    .el-input {
      height: 20px;
      .el-input__inner {
        height: 20px;
        border: none;
        padding-left: 5px;
        color: blue;
      }
    }
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
      