  <!-- 日志管理 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.operator"
                placeholder="操作人"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.type"
                placeholder="操作类型"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%"  height="calc(100vh - 330px)">
        <el-table-column prop="operationTime" label="操作时间" align="center" width="180"></el-table-column>
        <el-table-column prop="operator" label="操作人" align="center" width="180"></el-table-column>
        <el-table-column prop="type" label="操作类型" align="center" width="120"></el-table-column>
        <el-table-column prop="msg" label="内容" align="center" show-overflow-tooltip></el-table-column>
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
  </div>
</template>

<script>
import {
  SelectLogsPage
} from '@/api'
export default {
  data() {
    return {
      dialogBatchVisible: false,
      dialogBuyVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        operator: '',
        type: ''
      },
      form: {},
      buyUrl: '',
      tableData: []
    }
  },
  methods: {
    
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    loadData() {
      SelectLogsPage(this.search)
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
</style>
