  <!-- 商品监控管理 -->
  <template>
    <div class="m-t-20">
      <header class="main-body-search">
        <el-form :model="search" @submit.native.prevent>
          <el-row :gutter="50">
            <el-col :span="6" :xs="12">
              <el-form-item label class="nja-form-line">
                <el-input
                  v-model.trim="search.url"
                  placeholder="URL"
                  clearable
                  @keyup.enter.native="searchHandle"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" :xs="12">
              <el-form-item label class="nja-form-line">
                <el-input
                  v-model.trim="search.colors"
                  placeholder="颜色"
                  clearable
                  @keyup.enter.native="searchHandle"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" :xs="24">
              <el-form-item>
                <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
                <el-button type="primary" @click="showDetail" plain>设置</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </header>
      <el-main class="main-body-table">
        <el-table :data="tableData" stripe style="width: 100%" height="calc(100vh - 330px)">
          <el-table-column prop="goodsId" label="商品ID" align="center" width="150"></el-table-column>
          <el-table-column prop="url" label="URL" show-overflow-tooltip align="left" min-width="180"></el-table-column>
          <el-table-column prop="colors" label="颜色" align="center"></el-table-column>
          <el-table-column prop="bindingName" label="添加人" align="center"></el-table-column>
          <el-table-column prop="lastUpdateTime" label="最后更新时间" align="center"></el-table-column>
          <!-- <el-table-column label="监控状态" align="center">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="changeStatus(scope.row.id)"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </template>
          </el-table-column> -->
          <el-table-column label="操作"  fixed="right" align="center" width="100px">
            <template slot-scope="scope">
                <el-button size="mini" type="warning" @click="deleteRow(scope.row)">删除</el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty description="暂无数据"></el-empty>
          </template>
        </el-table>
        <!--工具条-->
        <el-pagination
          class="main-body-table-page"
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page="search.current"
          :page-size="search.size"
          :total="search.total"
        ></el-pagination>
      </el-main>
      <el-dialog
        title="设置"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        center
        v-dialogDrag
        custom-class="mydialog"
        width="700px"
      >
        <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
          <el-form-item class="dccform-line" label="代理服务" prop="proxyIp">
            <el-input v-model="form.proxyIp" placeholder="请输入代理服务地址"></el-input>
          </el-form-item>
          <el-form-item class="dccform-line" label="代理类型" prop="proxyType">
           <el-select v-model="form.proxyType">
              <el-option label="动态代理" value="dynamic"></el-option>
              <el-option label="隧道代理" value="tunnel"></el-option>
          </el-select>
          </el-form-item>
          <el-form-item class="dccform-line" label="监控频率" prop="rate" required :rules="[{required: true, message: '必填'}]">
            <el-input v-model.number="form.rate" type="number" min="0" placeholder="请输入监控频率">
              <template slot="append">毫秒</template>
            </el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
          <el-button style="width: 120px;" @click="dialogVisible = false">取消</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import {
    SelectPage,
    UpdateStatus,
    DeleteHandle
  } from '@/api/biz/goods'
  import {
    getSetting,
    setSetting
  } from '@/api'
  export default {
    data() {
      return {
        dialogVisible: false,
        search: {
          current: 1,
          size: 20,
          total: 0,
          url: '',
          colors: ''
        },
        form: {},
        tableData: []
      }
    },
    methods: {
      async showDetail() {
        this.form = {
          rate: await this.getConfig('bot-rate'),
          proxyIp: await this.getConfig('bot-proxy'),
          proxyType: await this.getConfig('bot-proxy-type'),
        }
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },

    deleteRow(row) {
      console.log(row)
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          DeleteHandle({ id: row.id }).then((res) => {
            if (res.code === 20000) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.loadData()
            }
          })
        })
      },
      saveHandle() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            // 保存
            let params = Object.assign({}, this.form)
            console.log(params)
            setSetting({code: 'bot-rate', value: params.rate})
            setSetting({code: 'bot-proxy', value: params.proxyIp})
            setSetting({code: 'bot-proxy-type', value: params.proxyType})
            this.dialogVisible = false
            this.$message({
                type: 'success',
                message: '操作成功'
              })
          }
        })
      },
      handleCurrentChange(val) {
        this.search.current = val
        this.loadData()
      },
      searchHandle() {
        this.search.current = 1
        this.loadData()
      },
      changeStatus(id) {
        UpdateStatus({ id }).then((res) => {
          console.log('UpdateStatus', res)
        })
      },
      loadData() {
        SelectPage(this.search)
          .then((res) => {
            console.log('success:', res)
            this.tableData = res.data.records
            this.search.total = res.data.total
          })
          .catch((err) => {
            console.log('err:', err)
          })
      },
      getConfig (code) {
        return getSetting({code}).then(res => {
          if (res.code === 20000) {
            return  res.data
          }
          return ''
        })
      }
    },
    mounted() {
      this.loadData()
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .tableimg {
    width: 45px;
  }
  .formlayout {
    padding: 20px 40px;
  }
  </style>
  