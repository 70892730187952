import { post } from "../../util/http"


/**
 * 列表分页
 * @param  params 
 */
export const SelectPage = params => post(`/goods/selectPage`, params)


/**
 * 修改状态
 * @param  params 
 */
export const UpdateStatus = params => post(`/goods/update`, params)


/**
 * 删除
 * @param  params 
 */
export const DeleteHandle = params => post(`/goods/del`, params)
