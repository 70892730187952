  <!-- apple -->
  <template>
    <div class="m-t-20">
      <el-main class="main-body-table">
        <vxe-toolbar ref="toolbarRef" :refresh="{queryMethod: searchHandle}" export custom>
          <template #tools>
            <vxe-switch v-model="dataempty" @change="changeHandle" style="margin-right: 10px" size="small" open-label="相同留空" close-label="显示数据"></vxe-switch>
          </template>
          <template #buttons>
            <input id="excel-upload-input" type="file" accept="application/*" class="c-hide" @change="handkeFileChange"
              v-show="false" />
            <el-button :type="proxyIp? 'primary' :'warning'" size="small" @click="setProxyIp" :loading="orderloading">{{proxyIp ? '修改代理' : '代理未设置'}}</el-button>
            <!-- <el-button :type="!isrun? 'success' :'warning'" size="small" @click="runTask">
              {{!isrun ? '上传账户' : '取消'}}
              </el-button> -->
              <el-dropdown style="margin: 0 10px;" v-if="!isrun">
                <el-button type="success" size="small">
                  上传账户
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="runTask">上传excel</el-dropdown-item>
                    <el-dropdown-item @click.native="showInput">手动录入</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
              <el-button v-else type="warning" size="small" @click="runTask">取消</el-button>
            <div style="margin-left: 20px;text-align:left;max-width: 800px;">
              上传excel，第一列是账户，第二列密码
              <div style="color: red;" v-if="proxyIp">代理IP:{{ proxyIp }}</div>
            </div>
          </template>
        </vxe-toolbar>
        <div style="height: calc(100vh - 290px);">
        <vxe-table
          border
          show-overflow
          :data="tableData"
          v-loading="loading"
          resizable
          align="center"
          ref="tableRef"
          height="auto"
          :row-config="{isHover: true}"
          :export-config="{ filename: '订单数据', sheetName: '数据', types: ['xlsx', 'csv', 'html', 'txt']}"
          :sort-config="{trigger: 'cell'}"
          :scroll-y="{enabled: true}"
        >
          <vxe-column title="#" field="index"  fixed="left" width="80px">

          </vxe-column>
          <vxe-column field="username" title="账户"  fixed="left" min-width="150px"></vxe-column>
          <vxe-column field="password" title="密码"  width="100px" ></vxe-column>
          <vxe-column field="appOrderNum" title="App订单数量" width="130px"></vxe-column>
          <vxe-column field="appleOrderNum" title="Apple订单数量" width="150px"></vxe-column>
          <vxe-column field="hasPaying" title="是否有待付款订单" width="150px"></vxe-column>
          <vxe-column field="balance" title="余额" sortable width="100px"></vxe-column>
          <vxe-column field="appMoney" title="app消费总额" sortable width="130px"></vxe-column>
          <vxe-column field="appleMoney" title="apple消费总额" sortable width="140px"></vxe-column>
          <vxe-column field="type" title="类型"  width="100px"></vxe-column>
          <vxe-column field="orderId" title="订单号"  width="120px"></vxe-column>
          <vxe-column field="orderStatus" title="订单状态"  width="120px"></vxe-column>
          <vxe-column field="productName" title="商品名称"  width="150px"></vxe-column>
          <vxe-column field="orderPlacedDate" title="下单日期" sortable width="130px"></vxe-column>
          <vxe-column field="deliveryDate" title="交货日期" sortable width="120px"></vxe-column>
          <vxe-column field="totalPrice" title="价格" sortable width="110px"></vxe-column>
          <vxe-column field="address" title="地址"  min-width="150px"></vxe-column>
          <vxe-column field="serialInfo" title="序列号"  width="120px"></vxe-column>
          <vxe-column field="trackingType" title="物流类型"  width="100px"></vxe-column>
          <vxe-column field="trackingNumber" title="快递单号"  width="100px"></vxe-column>
          <vxe-column field="trackingURL" title="物流链接"  width="100px"></vxe-column>
          <vxe-column field="orderEmail" title="链接"  width="200px">
            <template #default="{ row }">
              <el-link :href="row.orderEmail" target="_blank">{{ row.orderEmail }}</el-link>
            </template>
          </vxe-column>

          <vxe-column title="操作" width="100" fixed="right"  >
            <template #default="{ row }">
              <template v-if="!row.loading">
                <vxe-button type="text" v-if="row.first" status="primary" @click="refresh(row)" icon="vxe-icon-refresh"></vxe-button>
                <vxe-button type="text" status="primary" @click="removeRow(row)" icon="vxe-icon-delete"></vxe-button>
              </template>
              <span v-else>查询中</span>
              </template>
          </vxe-column>
        </vxe-table>
      </div>
      <div class="footer">
        <div>总 {{ tableData.length }} 条数据</div>
      </div>
      </el-main>
      
      <el-dialog
      title="请输入账户"
      :visible.sync="showInputDialog"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="700px"
    >
      <el-form
        ref="form"
        class="formlayout"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item prop="codes" label="账户">
          <el-input  type="textarea" v-model="form.codes" :rows="10" placeholder="请输入账户信息（格式：账户 密码）"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveInputData">运行</el-button>
        <el-button style="width: 120px;" @click="showInputDialog = false">取消</el-button>
      </span>
    </el-dialog>
    </div>
  </template>
      
      <script>
  import {
    putOrders,
    getOrders,
    cancelApple
  } from '@/api/biz/openapi'
  import Excel from 'exceljs'
  export default {
    data() {
      return {
        search: {},
        options: [],
        proxyIp: '',
        isrun: false,
        dataempty: false,
        loading: false,
        orderloading: false,
        form: {
          codes: ''
        },
        showInputDialog: false,
        eventSource: null,
        clientId: '',
        total: 0,
        idexData:[],
        tableData: []
      }
    },
    beforeDestroy () {
      if (this.eventSource) {
        this.eventSource.close()
      }
    },
    methods: {
      showInput () {
        this.form.codes = ''
        this.showInputDialog = true;
      },
      saveInputData () {
        let codes = this.form.codes.split('\n');
        if (codes && codes.length > 0) {
          this.showInputDialog = false;
          this.tableData = codes.filter(d => {
            let dd = this.trimSplit(d)
            return dd && dd.length == 2
          }).map((d,i) => {
            let dd = this.trimSplit(d)
            return {
                username: dd[0],
                password: dd[1],
                first: true,
                id: dd[0],
                index: i + 1,
                size: 1,
                loading: true
            }
          })
          this.idexData = this.tableData.map(d => d.username)
          this.uploadData(this.tableData.map(d => {
            return {
              username: d.username,
              password: d.password
            }
          }))
        } else {
          this.$message({
            type: 'warning',
            message: '请输入账户'
          })
        }
      },
      trimSplit(str) {
        console.log(str)
          // 正则表达式匹配一个或多个半角或全角空格
          const regex = /[\u0020\u3000]+|\t/g;
          // 使用冒号替换匹配的空格
          return str.replace(regex, ' ').split(' ');
      },
      changeHandle () {
        this.loadData()
        console.log('this.dataempty', this.dataempty)
        localStorage.setItem('cofig-dataempty', this.dataempty);
      },
      setProxyIp () {
        this.$prompt('请输入代理IP服务地址', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            inputValue: this.proxyIp
          }).then(({ value }) => {
            this.proxyIp = value
            localStorage.setItem('cofig-proxyIp', value)
          })
      },
      generateUUID() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              const r = Math.random() * 16 | 0;
              const v = c === 'x' ? r : (r & 0x3 | 0x8);
              return v.toString(16);
          });
      },
      startSse() {
        if (this.eventSource) {
          this.eventSource.close()
        }
        this.eventSource = new EventSource(`${this.$store.getters.baseApi}/open-api/apple/events?id=${this.clientId}`);
        this.eventSource.onmessage = (event) => {
          console.log('event', event)
          if (event.lastEventId != this.clientId ) {
            return;
          }
          // 结束
          if (event.data == 'finish') {
            this.orderloading = false
            this.isrun = false
          } else {
            let obj = JSON.parse(event.data)
            console.log(obj)
            let data = []
            let index = this.tableData.findIndex(d => d.username == obj.username)
            let objIndex = this.idexData.findIndex(d => d == obj.username)
            if (obj.orders && obj.orders.length > 0) {
              let hasPaying = obj.orders.some(d => d.orderStatus.includes('待付款'))
              data = obj.orders.map((d, i) => {
                  d.id = obj.username
                  d.username = obj.username
                  d.password = obj.password
                  d.index = objIndex+1
                  d.first = i == 0
                  d.hasPaying = hasPaying ?'YES':'NO'
                  d.loading =  false
                  d.size = obj.orders.length
                  d.orderId = d.orderId.replace('order-', '')
                  d.orderEmail =  `https://www.apple.com/xc/cn/vieworder/${d.orderId}/${obj.username}`
                  d.appMoney = obj.appMoney
                  d.appleMoney = obj.appleMoney
                  d.balance = obj.balance
                  d.appOrderNum = obj.appOrderNum
                  d.appleOrderNum = obj.appleOrderNum
                  if (this.dataempty && i !== 0) {
                    d.appMoney = ''
                    d.appleMoney = ''
                    d.balance = ''
                    d.appOrderNum = ''
                    d.appleOrderNum = ''
                    d.username = ''
                    d.password = ''
                    d.hasPaying = ''
                    d.index = ''
                  }
                return d
              })
            } else {
              data = [{
                  id: obj.username,
                  first: true,
                  loading: false,
                  index: objIndex+1,
                  size: 1,
                  ...obj
              }]
            }
            // 删除原来的
            let list = this.tableData.filter(d => d.id != obj.username)
            list.splice(index,0,...data)
            console.log('list', this.idexData, list)
            this.tableData = list
          }
        };
      },
      async removeRow (row) {
        let index = this.tableData.findIndex(d => d.code == row.code)
        if (index != -1) {
          const $table = this.$refs.tableRef
          await $table.remove(row)
          this.tableData.splice(index, 1)
        }
      },
      searchHandle() {
        this.loadData()
      },
      loadPage() {
        getOrders({})
          .then((res) => {
            console.log('getOrders:', res)
            let data = []
            res.data.data.forEach((obj, index) => {
              if (obj.orders && obj.orders.length > 0) {
                let hasPaying = obj.orders.some(d => d.orderStatus.includes('待付款'))
                data.push(...obj.orders.map((d, i) => {
                  d.id = obj.username
                  d.username = obj.username
                  d.password = obj.password
                  d.index = index+1
                  d.first = i == 0
                  d.hasPaying = hasPaying ?'YES':'NO'
                  d.size = obj.orders.length
                  d.orderId = d.orderId.replace('order-', '')
                  d.orderEmail =  `https://www.apple.com/xc/cn/vieworder/${d.orderId}/${obj.username}`
                  d.appMoney = obj.appMoney
                  d.appleMoney = obj.appleMoney
                  d.balance = obj.balance
                  d.appOrderNum = obj.appOrderNum
                  d.appleOrderNum = obj.appleOrderNum

                  if (this.dataempty && i !== 0) {
                    d.appMoney = ''
                    d.appleMoney = ''
                    d.balance = ''
                    d.appOrderNum = ''
                    d.appleOrderNum = ''
                    d.username = ''
                    d.password = ''
                    d.hasPaying = ''
                    d.index = ''
                  }
                  
                  return d
                }))
              } else {
                data.push({
                  id: obj.username,
                  first: true,
                  index: index+1,
                  hasPaying: '-',
                  size: 1,
                  ...obj
                })
              }
            })
            this.idexData = res.data.data.map(d => d.username)
            this.tableData = data
            this.orderloading = res.data.todo
            this.isrun = this.orderloading
            this.$nextTick(() => {
              this.loading = false
            })
        });
      },
      runTask() {
        if (this.isrun) {
          this.isrun = false
          this.cancelData()
        } else {
          this.importData()
        }
      },
      cancelData() {
        this.orderloading = true
        cancelApple({}).then((res) => {
          console.log('cancelApple', res)
          this.orderloading = false
          this.loadData()
        })
      },
      refresh(row){
        // 刷新账户
        row.loading = true
        let index = this.tableData.findIndex( d=> d.username == row.username)
        this.tableData.splice(index, row.size, row)
        row.appMoney = ''
        row.appleMoney = ''
        row.balance = ''
        row.appOrderNum = ''
        row.appleOrderNum = ''
        this.uploadData([row], true)
      },
      uploadData (data, append = false) {
        this.orderloading = true
        this.isrun = true
        putOrders({
          proxyIp: this.proxyIp,
          users: data,
          append
        }).then(res => {
          console.log('putOrders', res)
          this.startSse();
        })
      },
      loadData() {
        this.loading = true
        this.tableData=[]
        this.loadPage(1)
      },
      importData() {
        document.getElementById('excel-upload-input').click()
      },
      getCellValue (value) {
        if (typeof value == 'object') {
          if (!value.text && value.richText && value.richText.length > 0) {
            return value.richText[0].text
          }
          return value.text
        }
        return value
      },
      handkeFileChange() {
          // 导入
          let that = this
          let file = document.getElementById('excel-upload-input').files[0]
          console.log('file', file)
          const buffer = new FileReader()
          buffer.readAsArrayBuffer(file)
          buffer.onload = () => {
            var workbook = new Excel.Workbook()
            document.getElementById('excel-upload-input').value = null
            workbook.xlsx.load(buffer.result).then(workbook => {
              console.log(workbook)
              // 获取工作表
              const worksheet = workbook.getWorksheet(1)
              // 迭代工作表中具有值的所有行
              let data = []
              worksheet.eachRow((row, rowNumber) => {
                console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values))
                if (rowNumber > 1 && row.values[1] && row.values[2]) {
                  data.push({
                    username: that.getCellValue(row.values[1]),
                    password: that.getCellValue(row.values[2])
                  })
                }
              })
              console.log(data)
              that.idexData = data.map(d => d.username)
              that.tableData = data.map((d, i) => {
                d.first = true
                d.id = d.username
                d.index = i + 1
                d.size = 1
                d.loading = true
                return d
              })
              that.uploadData(data)
            })
          }
        },
    },
    async mounted() {
      this.$nextTick(() => {
        // 将表格和工具栏进行关联
        const $table = this.$refs.tableRef
        const $toolbar = this.$refs.toolbarRef
        if ($table && $toolbar) {
          $table.connect($toolbar)
        }
      })
      this.proxyIp = localStorage.getItem('cofig-proxyIp') || '';
      this.dataempty = localStorage.getItem('cofig-dataempty') == 'true'  ? true : false;
      console.log('this.dataempty ', this.dataempty )
      this.clientId = this.generateUUID()
      this.startSse();
      this.loadData()
    }
  }
  </script>
      
  <style lang="scss" scoped>
  .formlayout {
    padding: 20px 40px;
  }
  .changed {
    color: $primary-color;
  }
  ::v-deep {
    .el-table__body-wrapper {
      height: calc(100% - 40px) !important;
    }
    .el-input__inner {
      height: 34px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  .labelsele {
    ::v-deep {
      .el-input {
        height: 20px;
        .el-input__inner {
          height: 20px;
          border: none;
          padding-left: 5px;
          color: blue;
        }
      }
      .el-input__suffix{
          display: none;
        }
    }
  }
  </style>
      