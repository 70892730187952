  <!-- 用户管理 -->
<template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.username"
                placeholder="用户名称"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line">
              <el-input
                v-model.trim="search.nickname"
                placeholder="昵称"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="showDetail({})" plain>新建用户</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="calc(100vh - 330px)">
        <el-table-column prop="username" label="用户名称" align="center" width="180"></el-table-column>
        <el-table-column prop="nickname" label="昵称" align="center"></el-table-column>
        <el-table-column prop="role" label="角色" align="center">
          <template slot-scope="scope">{{ types[scope.row.role] }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="0"
              :inactive-value="-1"
              @change="changeUserStatus(scope.row.id)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="160px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="primary" @click="showDetail(scope.row)">编辑</el-button>
              <el-button size="mini" type="warning"  @click="updatePwd(scope.row)">重置密码</el-button>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      :title="form.id ? '编辑用户' : '新增用户'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="700px"
    >
      <el-form ref="form" class="formlayout" :model="form" label-width="90px" label-position="left">
        <el-form-item class="dccform-line" label="用户名称" prop="username" required :rules="[{required: true, message: '必填'}]">
          <el-input v-model="form.username" placeholder="请输入用户名称" :disabled="form.id"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="用户昵称" prop="nickname" required :rules="[{required: true, message: '必填'}]">
          <el-input v-model="form.nickname" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="登录密码" prop="password" v-if="!form.id" required :rules="[{required: true, message: '必填'}]">
          <el-input v-model="form.password" placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item class="dccform-line" label="角色" prop="role" required :rules="[{required: true, message: '必填'}]">
          <el-select v-model="form.role" style="width: 100%;">
            <el-option :label="r.role" :value="r.id" v-for="(r, i) in roles" :key="i"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
        <el-button style="width: 120px;" @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  SelectUserPage,
  UpdateUserStatus,
  RegisterRequest,
  RegisterUpdateUser,
  SelectListRole
} from '@/api'
import { md5 } from '@/util/crypto'
export default {
  data() {
    return {
      dialogVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        username: '',
        nickname: ''
      },
      types: {},
      roles: [],
      form: {
        username: '',
        nickname: '',
        password: '',
        role: ''
      },
      tableData: []
    }
  },
  methods: {
    showDetail(row) {
      this.form = Object.assign(
        {
          username: '',
          nickname: '',
          password: '',
          role: ''
        },
        row
      )
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    saveHandle() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          let API = RegisterRequest
          if (params.id) {
            API = RegisterUpdateUser
            delete params.password
          } else {
            params.password = md5(params.password)
          }
          delete params.lastUpdateTime
          delete params.createTime
          API(params).then((res) => {
            console.log('RegisterRequest', res)
            if (res.code === 20000) {
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.dialogVisible = false
              this.loadData()
            }
          })
        }
      })
    },
    updatePwd(row) {
      this.$prompt('请输入新密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '格式不正确'
      }).then(({ value }) => {
        // 修改密码
        RegisterUpdateUser({
          username: row.username,
          password: md5(value)
        }).then((res) => {
          console.log('RegisterUpdateUser', res)
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.loadData()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    changeUserStatus(id) {
      UpdateUserStatus({ id }).then((res) => {
        console.log('UpdateUserStatus', res)
      })
    },
    loadData() {
      SelectUserPage(this.search)
        .then((res) => {
          console.log('success:', res)
          this.tableData = res.data.records
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    loadDict () {
      SelectListRole().then(res => {
        console.log('SelectListRole', res)
        if (res.code === 20000) {
          this.types = {}
          this.roles = res.data.map(d => {
            this.types[d.id] = d.role
            return d
          })
         
        }
      })
    }
  },
  mounted() {
    this.loadDict()
    this.loadData()
  }
}
</script>

<style lang="scss" scoped>
.tableimg {
  width: 45px;
}
.formlayout {
  padding: 20px 40px;
}
</style>
