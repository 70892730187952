  <!-- apitoken -->
  <template>
  <div class="m-t-20">
    <header class="main-body-search">
      <el-form :model="search" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="6" :xs="12">
            <el-form-item label class="nja-form-line" prop="keyword">
              <el-input
                v-model.trim="search.keyword"
                placeholder="关键字"
                clearable
                @keyup.enter.native="searchHandle"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
              <el-button type="primary" @click="showDetail" plain>新增API用户</el-button>
              <el-button type="warning" @click="showUsedStat" plain>使用统计</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </header>
    <el-main class="main-body-table">
      <el-table :data="tableData" stripe style="width: 100%" height="calc(100vh - 330px)">
        <el-table-column prop="name" label="用户" align="center" min-width="150"></el-table-column>
        <el-table-column prop="token" label="Token" align="center" min-width="110"></el-table-column>
        <el-table-column prop="balance" label="余额" align="center" min-width="100"></el-table-column>
        <el-table-column prop="price" label="实时价格/次" align="center" width="100"></el-table-column>
        <el-table-column prop="cachePrice" label="缓存价格/次" align="center" width="100"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="-1"
              @change="changeUserStatus(scope.row)"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" min-width="120"></el-table-column>
        <el-table-column label="操作"  fixed="right" align="center" min-width="200px">
          <template slot-scope="scope">
            <div style="display: flex;">
              <el-button size="mini" type="primary" @click="showDetail(scope.row)">编辑</el-button>
              <el-button size="mini" type="primary" @click="showRecharge(scope.row)">充值</el-button>
              <el-button size="mini" type="success" @click="showUsed(scope.row)">使用记录</el-button>
              <el-button size="mini" type="warning" @click="deleteRow(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据"></el-empty>
        </template>
      </el-table>
      <!--工具条-->
      <el-pagination
        class="main-body-table-page"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page="search.current"
        :page-size="search.size"
        :total="search.total"
      ></el-pagination>
    </el-main>
    <el-dialog
      :title="form.id ? '编辑API用户' : '新增API用户'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="800px"
    >
      <el-form
        ref="form"
        class="formlayout"
        :model="form"
        label-width="120px"
        label-position="right"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="用户"
              prop="name"
              required
              :rules="[{required: true, message: '必填'}]"
            >
              <el-input v-model="form.name" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="次数" prop="sizes" required :rules="[{required: true, message: '必填'}]">
            <el-input v-model.number="form.sizes" type="number"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="余额" prop="balance">
            <el-input v-model.number="form.balance"  disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实时价格/次" prop="price" required :rules="[{required: true, message: '必填'}]">
            <!-- <el-input v-model.number="form.price" min="0.0" type="number"></el-input> -->
            <el-input-number style="width: 100%;"  v-model="form.price" :precision="3" :step="0.01" :max="100"></el-input-number>
            </el-form-item>
          </el-col> 
          <el-col :span="12">
            <el-form-item label="缓存价格/次" prop="cachePrice" required :rules="[{required: true, message: '必填'}]">
            <!-- <el-input v-model.number="form.cachePrice" type="number"></el-input> -->
            <el-input-number style="width: 100%;" v-model="form.cachePrice" :precision="3" :step="0.01" :max="100"></el-input-number>
            </el-form-item>
          </el-col> 
          <el-col :span="24">
            <el-form-item label="Token" prop="token" required :rules="[{required: true, message: '必填'}]">
            <el-input v-model="form.token">
              <template slot="append">
                <el-button  @click="refreshToken">刷新token</el-button>
              </template>
            </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          class="dccform-line"
          label="备注"
          prop="comment"
        >
          <el-input v-model="form.comment" type="textarea" :rows="2" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px;" @click="saveHandle">保存</el-button>
        <el-button style="width: 120px;" @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="使用记录"
      :visible.sync="dialogUsedVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="80%"
    >
    <el-table :data="tableUsedData" stripe style="width: 100%" height="calc(100vh - 330px)">
        <el-table-column prop="name" label="用户" align="center" min-width="150"></el-table-column>
        <el-table-column prop="requestMetod" label="请求接口" align="center" min-width="100"></el-table-column>
        <!-- <el-table-column prop="used" label="使用" align="center" min-width="100"></el-table-column> -->
        <el-table-column prop="price" label="消费" align="center" min-width="100"></el-table-column>
          <el-table-column prop="createTime" label="时间" align="center" min-width="120"></el-table-column>
          <template #empty>
            <el-empty description="暂无数据"></el-empty>
          </template>
        </el-table>
        <!--工具条-->
        <el-pagination
          class="main-body-table-page"
          layout="total, prev, pager, next, jumper"
          @current-change="handleUsedCurrentChange"
          :current-page="usedForm.current"
          :page-size="usedForm.size"
          :total="usedForm.total"
        ></el-pagination>
    </el-dialog>

    <el-dialog
      title="使用统计"
      :visible.sync="dialogUsedStatVisible"
      :close-on-click-modal="false"
      center
      v-dialogDrag
      custom-class="mydialog"
      width="80%"
    >
        <el-form :model="statForm" @submit.native.prevent>
        <el-row :gutter="50">
          <el-col :span="5" :xs="12" :offset="2">
            <el-form-item label class="nja-form-line" prop="name">
              <el-input
                v-model.trim="statForm.name"
                placeholder="名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="12">
            <el-form-item  label class="nja-form-line" prop="day">
              <el-date-picker
                v-model="statForm.day"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" :xs="24">
            <el-form-item>
              <el-button type="primary" @click="searchStatHandle" icon="el-icon-search">查 询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
          <Chart id="usedChart" class="chart" :data="chartData"></Chart>
      </div>
      <div class="row">
          <div class="item" style="background-color: #4675C0">缓存总次数： {{ statForm.totalCache }}</div>
          <div class="item" style="background-color: #86E3CE">实时总次数(查询spu+查询详情+实时价格)： {{ statForm.total }}</div>
        </div>
        <div class="row">
          <div class="item" style="background-color: #4675C0">缓存总消费： {{ statForm.totalCacheMoney }}元</div>
          <div class="item" style="background-color: #86E3CE">实时总消费： {{ statForm.totalMoney }}元</div>
        </div>
    </el-dialog>
  </div>
</template>
    
<script>
import {
  SelectPage,
  InsertHandle,
  UpdateHandle,
  SelectUsedPage,
  DeleteHandle,
  rechargeMoney,
  getUsedStat
} from '@/api/biz/apitoken'
import Chart from '../../admin/../../components/Chart.vue'
import moment from 'moment'
export default {
  components: {Chart},
  data() {
    return {
      dialogVisible: false,
      dialogUsedVisible: false,
      dialogUsedStatVisible: false,
      search: {
        current: 1,
        size: 20,
        total: 0,
        keyword: ''
      },
      usedForm: {
        current: 1,
        size: 20,
        total: 0,
        name: ''
      },
      statForm: {
        name: '',
        day: moment().format('YYYY-MM-DD'),
        totalCache: 0,
        total: 0,
        totalMoney: 0,
        totalCacheMoney: 0
      },
      imgloading: false,
      form: {
        id: '',
        name: '',
        token: '',
        balance: '' ,
        price: 0.0,
        cachePrice: 0.0,
        comment: ''
      },
      chartAllData: {

      },
      chartData: {},
      tableData: [],
      tableUsedData: []
    }
  },
  methods: {
    showRecharge (row) {
      this.$prompt('请输入充值金额', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^.+$/,
          inputErrorMessage: '必填'
        }).then(({ value }) => {
          rechargeMoney({
            id: row.id,
            money: value
          }).then((res) => {
            if (res.code === 20000) {
              this.$message({
                  type: 'success',
                  message: '充值成功'
                })
              this.loadData()
            }
          })
        }).catch(() => {   
        });
    },
    showUsedStat () {
      this.dialogUsedStatVisible = true
      this.searchStatHandle()
      getUsedStat({}).then(res => {
        // 统计
        let obj = res.data.find(d => d.type == '查询缓存价格')
        if (obj && obj.used) {
          this.statForm.totalCache = obj.used
        }
        if (obj && obj.money) {
          this.statForm.totalCacheMoney = obj.money
        }
        this.statForm.total = res.data.filter(d => d.type != '查询缓存价格').map(d => d.used).reduce((a, b) => a+=b, 0)
        this.statForm.totalMoney = res.data.filter(d => d.type != '查询缓存价格').map(d => d.money|| 0).reduce((a, b) => a+=b, 0)
      })
    },
    searchStatHandle () {
      // 查询统计
      getUsedStat(this.statForm).then(res => {
        console.log('getUsedStat', res)
        if (res.code === 20000) {
          let type = {
            '查询spu': '#FA897B',
            '查询详情': '#D0E6A5',
            '查询缓存价格': '#4675C0',
            '查询价格': '#86E3CE'
          }
          this.chartData = {
              xAxis: {
                type: 'category',
                data: res.data.map(d => d.type)
              },
              yAxis: {
                type: 'value'
              },
              series: [
                {
                  type: 'bar',
                  label: {
                    show: true,
                    fontSize: 30,
                    position: 'inside'
                  },
                  barWidth: 200,
                  data: res.data.map(d => {
                    return  {
                        value: d.used,
                        itemStyle: {
                          color: type[d.type]
                        }
                      }
                  })
                }
              ]
            }
        }
      })
    },
    refreshToken () {
      var result = '';  
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';  
      var charactersLength = characters.length;  
      for (var i = 0; i < 16; i++) {  
          result += characters.charAt(Math.floor(Math.random() * charactersLength));  
      }  
       this.form.token =  result
    },
    showDetail(row) {
      this.imgloading = true
      this.form = Object.assign(
        {
          id: '',
          name: '',
          token: '',
          balance: '' ,
          price: 0.0,
          cachePrice: 0.0,
          comment: ''
        },
        row
      )
      if (!this.form.id) {
        this.refreshToken()
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    showUsed (row) {
      // 查看使用记录
      this.dialogUsedVisible = true
      this.usedForm.name = row.name
      this.usedForm.current = 1
      this.loadUsedData()
    },
    saveHandle() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          // 保存
          let params = Object.assign({}, this.form)
          delete params.createTime
          let API = InsertHandle
          if (params.id) {
            API = UpdateHandle
          }
          API(params).then((res) => {
            console.log('保存用户', res)
            if (res.code === 20000) {
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.dialogVisible = false
              this.loadData()
            }
          })
        }
      })
    },
    deleteRow(row) {
      console.log(row)
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteHandle({ id: row.id }).then((res) => {
          if (res.code === 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.loadData()
          }
        })
      })
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.loadData()
    },
    handleUsedCurrentChange (val) {
      this.usedForm.current = val
      this.loadUsedData()
    },
    searchHandle() {
      this.search.current = 1
      this.loadData()
    },
    changeUserStatus(row) {
      UpdateHandle(row).then((res) => {
        console.log('UpdateUserStatus', res)
      })
    },
    loadUsedData () {
      SelectUsedPage(this.usedForm)
        .then((res) => {
          console.log('SelectPage:', res)
          this.tableUsedData = res.data.list || []
          this.usedForm.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    loadData() {
      SelectPage(this.search)
        .then((res) => {
          console.log('SelectPage:', res)
          this.tableData = res.data.list || []
          this.search.total = res.data.total
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
  },
  async mounted() {
    this.loadData()
  }
}
</script>
    
<style lang="scss" scoped>
.formlayout {
  padding: 20px 40px;
}
::v-deep {
  .el-table__body-wrapper {
    height: calc(100% - 40px) !important;
  }
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.item {
  min-width: 300px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  background-color: #4675C0;
  color: #fff;
  text-align: center;
  margin: 10px;
  font-size: 20px;
  padding: 0 20px;
}
.chart {
  width: 100%;
  height: 400px;
}
</style>
    